import { EnumFormFieldType, EnumRolePostion } from '../../types/enums'
import { IFormField } from '../../types/interface'
import { Moment } from 'moment'

export const fieldsList: IFormField[] = [
  {
    name: 'Store_Code',
    label: 'Store',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'storeList',
    onChange: ({ value, form, optionsMap }) => {
      const storeCode = value.split('--')[0]
      const store = optionsMap.ownStore.find(store => store.Store_Code === storeCode)
      form.setFields([
        { name: 'Region', value: store?.Region },
        { name: 'Status', value: store?.Status },
      ])
    },
  },
  {
    name: 'Region',
    label: 'Area',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'regionList',
    extra: {
      disabled: true,
    },
    col: 12,
  },
  {
    name: 'Status',
    label: 'Status',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'statusList',
    extra: {
      disabled: true,
    },
    col: 12,
  },
  {
    name: 'Closed_Date',
    label: 'Closed Date',
    require: true,
    type: EnumFormFieldType.DatePicker,
    col: 12,
  },
  {
    name: 'Reopen_Date',
    label: 'Reopen Date',
    require: false,
    type: EnumFormFieldType.DatePicker,
    col: 12,
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value) return Promise.resolve('')
          const limitTime = (getFieldValue('Closed_Date') as Moment)?.startOf('day').unix()
          const currentTime = value?.startOf('day').unix()
          if (limitTime && limitTime < currentTime) {
            return Promise.resolve()
          }
          return Promise.reject(
            new Error('Reopen Date 必须晚于 Closed Date。')
          )
        },
      }),
    ],
  },
  {
    name: 'Remark',
    label: 'Remark',
    require: true,
    type: EnumFormFieldType.Input,
    rules: [
      {
        validator: (_, value) => {
          if (!value) return Promise.resolve('')
          if (!value.trim()) return Promise.reject(new Error(`Remark 为必填项！`))
          return Promise.resolve()
        },
      },
    ],
  }
]

export const filterFields: IFormField[] = [
  {
    name: 'Region',
    label: 'Area',
    type: EnumFormFieldType.Select,
    optionsName: 'regionList',
    col: 12,
  },
  {
    name: 'Status',
    label: 'Status',
    type: EnumFormFieldType.Select,
    optionsName: 'statusList',
    col: 12,
  },
  {
    name: 'Store_Code',
    label: 'CN Code',
    type: EnumFormFieldType.AutoComplete,
    optionsName: 'storeCodeList',
    col: 12,
    rules: [
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '只能输入数字或字母',
      },
    ],
  },
  {
    name: 'Store_Name',
    label: 'Store',
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Closed_Date',
    label: 'Closed Date Range',
    type: EnumFormFieldType.DateRangePicker,
    col: 12,
  },
  {
    name: 'Reopen_Date',
    label: 'Reopen Date Range',
    type: EnumFormFieldType.DateRangePicker,
    col: 12,
  },
  {
    name: 'Remark',
    label: 'Remark',
    type: EnumFormFieldType.Input,
  },
]
