import React, { useEffect, useMemo, useState } from 'react'
import { fieldsList } from './FormField'
import FormContent from '../form/Form'
import apiCollection from '../../api'
import { EnumConfigType, EnumOperateType } from '../../types/enums'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import { ITemporaryClosedItem } from '../../api/apiModel/temporaryClosed'
import { IFromMapItem } from '../../types/interface'
import { message } from 'antd'
import { IOwnStoreItem } from '../../api/apiModel/ownStore'

interface TemporaryClosedDetailProps {
  isEdit?: boolean
  editData?: ITemporaryClosedItem
  storeList: IOwnStoreItem[]
  operateCallback: (type: EnumOperateType) => void
}

const TemporaryClosedDetailContent = ({
  isEdit,
  editData,
  storeList,
  operateCallback,
}: TemporaryClosedDetailProps) => {
  const { configMap } = useStoreViewContext()

  const [initData, setInitData] = useState(null)

  const optionsMap = useMemo(() => {
    const formationStoreList =
      storeList?.map((item) => ({
        id: item?.Store_Code,
        value: `${item.Store_Code} -- ${item.Name_CN}`,
      })) ?? []
    return {
      regionList: configMap.get(EnumConfigType.Store_Region),
      statusList: configMap.get(EnumConfigType.Store_Status),
      storeList: formationStoreList,
      ownStore: storeList.map(({ Store_Code, Region, Status }) => ({
        Store_Code,
        Region,
        Status
      }))
    }
  }, [storeList, configMap])

  const storeDataMap = useMemo(() => {
    const _map: Map<string, string> = new Map()
    storeList?.forEach((item) => {
      _map.set(item?.Store_Code, item?.Name_CN)
    })
    return _map
  }, [storeList])

  const formMap = useMemo((): IFromMapItem[] => {
    const fields = fieldsList?.map((item) =>
        ['Store_Code'].includes(item.name)
          ? { ...item, extra: { ...item.extra, disabled: isEdit } }
          : item
      ) ?? []
    return [
      {
        panelName: 'Temporary Closed',
        fieldsList: fields,
        showSubmitButton: true,
        showCancelButton: true,
      },
    ]
  }, [fieldsList, isEdit])

  const submitCallback = async ({ Region, Status, ...formData }: ITemporaryClosedItem) => {
    const params: ITemporaryClosedItem = {
      ...formData,
      Reopen_Date: formData?.Reopen_Date ?? null,
      Store_Name: storeDataMap?.get(formData.Store_Code),
    }

    const result = isEdit
      ? await apiCollection.updateTemporaryClosed({ Id: editData?.Id, ...params })
      : await apiCollection.addTemporaryClosed(params)
    if (result.success) {
      operateCallback(EnumOperateType.Submit)
    } else {
      message.error(result.message)
      throw Error(result.message)
    }
  }

  const cancelCallback = () => operateCallback(EnumOperateType.Cancel)

  const initFormatData = () => {
    if (!editData || !Object.keys(editData).length) return
    const {
      Closed_Date,
      Reopen_Date,
      ...defaultTemporaryClosedData
    } = editData

    setInitData({
      ...defaultTemporaryClosedData,
      Closed_Date: Closed_Date * 1000,
      Reopen_Date: Reopen_Date ? Reopen_Date * 1000 : null,
      Region: storeList.find(store => store.Store_Code === editData.Store_Code)?.Region,
      Status: storeList.find(store => store.Store_Code === editData.Store_Code)?.Status,
    })
  }

  useEffect(() => {
    initFormatData()
  }, [editData])

  return (
    <>
      <FormContent
        formMap={formMap}
        optionsMap={optionsMap}
        isEdit={isEdit}
        initData={initData}
        formKeyName={initData?.Id?.toString()}
        submitCallback={submitCallback}
        cancelCallback={cancelCallback}
      />
    </>
  )
}

export default TemporaryClosedDetailContent
