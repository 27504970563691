import React, { useMemo } from 'react'
import styled from 'styled-components'
import { filterFields } from './FormField'
import FormContent from '../form/Form'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import {
  EnumConfigType,
  EnumExcelType,
  EnumFormFieldType,
} from '../../types/enums'
import { IFromMapItem } from '../../types/interface'
import Download from './../icon/Download'
import IconContent from './../icon/IconContent'
import { PlusOutlined } from '@ant-design/icons'

const TemporaryClosedFilterContainer = styled.div`
  padding-bottom: 4.8rem;
  position: relative;
`

interface ITemporaryClosedFilterProps {
  filterData: object
  storeCodeList: { value: string; label?: string }[]
  changeFilter: (filterData: object) => void
  addTemporaryClosed: () => void
}

const TemporaryClosedFilterContent = ({
  filterData,
  storeCodeList,
  changeFilter,
  addTemporaryClosed,
}: ITemporaryClosedFilterProps) => {
  const { configMap } = useStoreViewContext()

  const optionsMap = useMemo(() => ({
    regionList: configMap.get(EnumConfigType.Store_Region),
    statusList: configMap.get(EnumConfigType.Store_Status),
    storeCodeList,
  }), [storeCodeList, configMap])

  const initFilterData = useMemo(() => {
    const initData = { ...filterData }
    filterFields.forEach((item) => {
      switch (item.type) {
        case EnumFormFieldType.DateRangePicker:
          if (initData[item.name]?.includes('-')) {
            initData[item.name] = initData[item.name]
              .split('-')
              .map((timeItem) => timeItem * 1000)
          }
          break
      }
    })
    return initData
  }, [filterData, filterFields])

  const formMap: IFromMapItem[] = [
    {
      panelName: 'Temporary Closed Filter',
      fieldsList: filterFields,
      showSubmitButton: true,
      submitButtonLabel: '搜索',
      cancelButtonLabel: '重置',
      buttonPosition: 'right',
      showCancelButton: true,
      customFormButton: [
        <Download fileUrl={`report/${EnumExcelType.StoreReport}`} />,
        <IconContent
          icon={PlusOutlined}
          tips={'新增Temporary Closed'}
          clickCallback={addTemporaryClosed}
        />,
      ],
    },
  ]

  const resetFilter = () => changeFilter({})

  const submitFilter = (form) => {
    const data = { ...form }

    filterFields.forEach((item) => {
      switch (item.type) {
        case EnumFormFieldType.DateRangePicker:
          data[item.name] = data[item.name]?.join('-')
          break
      }
    })

    changeFilter(data)
  }

  return (
    <TemporaryClosedFilterContainer>
      <FormContent
        isEdit={true}
        initData={initFilterData}
        hiddenPanel={true}
        disableCatch={true}
        formMap={formMap}
        optionsMap={optionsMap}
        expandIcon={() => ''}
        submitCallback={submitFilter}
        cancelCallback={resetFilter}
      />
    </TemporaryClosedFilterContainer>
  )
}

export default TemporaryClosedFilterContent
