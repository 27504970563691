import React, { useEffect, useState } from 'react'
import TemporaryClosedDetailContent from './Detail'
import TemporaryClosedListContent from './List'
import { ITemporaryClosedItem } from '../../api/apiModel/temporaryClosed'
import apiCollection from '../../api'
import { EnumMenuTypes, EnumOperateType } from '../../types/enums'
import { IPageInfo } from '../../types/interface'
import Menu from '../tools/Menu'
import { IOwnStoreItem } from '../../api/apiModel/ownStore'

export interface IOperateCallbackParams {
  item?: ITemporaryClosedItem
  filter?: object
  pageInfo?: IPageInfo
}

const TemporaryClosedContent = () => {
  const [isShowDetail, displayDetailPage] = useState<boolean>(false)
  const [temporaryClosedList, setTemporaryClosedList] = useState<ITemporaryClosedItem[]>([])
  const [editData, setEditData] = useState<ITemporaryClosedItem>()
  const [filterData, setFilterData] = useState<object>({})
  const [pageInfo, setPageInfo] = useState<IPageInfo>({ page: 1, size: 10 })
  const [isLoading, showLoading] = useState<boolean>(false)
  const [temporaryClosedTotal, setTemporaryClosedTotal] = useState<number>(0)
  const [storeList, setStoreList] = useState<IOwnStoreItem[]>([])
  const [storeCodeList, setStoreCodeList] = useState<{ value: string }[]>([])

  const getTemporaryClosedList = async () => {
    showLoading(true)
    const result = await apiCollection.getTemporaryClosedList({
      ...filterData,
      ...pageInfo,
    })

    if (result.success) {
      const { data: list, total } = result.data
      setTemporaryClosedList(list)
      setTemporaryClosedTotal(total)
    }
    showLoading(false)
  }

  const getAllStoreList = async () => {
    const result = await apiCollection.getOwnStoreList({})
    const storeList = result.success ? result?.data?.data : []
    const storeCodeList =
      storeList?.map((item) => ({ value: item?.Store_Code })) ?? []
    setStoreList(storeList)
    setStoreCodeList(storeCodeList)
  }

  const operateCallback = (
    type: EnumOperateType,
    data?: IOperateCallbackParams
  ) => {
    switch (type) {
      case EnumOperateType.Update:
        displayDetailPage(true)
        setEditData(data.item)
        break

      case EnumOperateType.Add:
        displayDetailPage(true)
        break

      case EnumOperateType.Cancel:
        setEditData(null)
        displayDetailPage(false)
        break

      case EnumOperateType.Submit:
        setEditData(null)
        displayDetailPage(false)
        getTemporaryClosedList()
        break

      case EnumOperateType.Filter:
        setFilterData(data.filter)
        setPageInfo((current) => ({ ...current, page: 1 }))
        break

      case EnumOperateType.ChangePage:
        setPageInfo((current) => ({ ...current, ...data.pageInfo }))
        break
    }
  }

  useEffect(() => {
    getAllStoreList()
  }, [])

  useEffect(() => {
    getTemporaryClosedList()
  }, [pageInfo])

  return (
    <>
      <Menu
        current={EnumMenuTypes.TemporaryClosedList}
        refreshPage={() => {
          operateCallback(EnumOperateType.Cancel)
        }}
      />
      {isShowDetail && (
        <TemporaryClosedDetailContent
          isEdit={!!editData?.Store_Code}
          editData={editData}
          storeList={storeList}
          operateCallback={operateCallback}
        />
      )}
      {!isShowDetail && (
        <TemporaryClosedListContent
          list={temporaryClosedList}
          total={temporaryClosedTotal}
          filterData={filterData}
          isLoading={isLoading}
          storeList={storeList}
          pageInfo={pageInfo}
          storeCodeList={storeCodeList}
          operateCallback={operateCallback}
        />
      )}
    </>
  )
}

export default TemporaryClosedContent
