import React from 'react'

import { AuthorizationProvider } from '../context/authorizationContext/AuthorizationContext'
import { StoreViewProvider } from '../context/storeViewContext/StoreViewContext'
import Header from '../component/tools/Header'
import BodyContainer from '../component/layout/BodyContainer'
import TemporaryClosed from '../component/temporaryClosed/TemporaryClosed'

import { EnumMenuTypes } from '../types/enums'

const TemporaryClosedPageContainer = () => {
  return (
    <AuthorizationProvider>
      <StoreViewProvider>
        <Header title={EnumMenuTypes.TemporaryClosedList} />
        <BodyContainer>
          <TemporaryClosed />
        </BodyContainer>
      </StoreViewProvider>
    </AuthorizationProvider>
  )
}

export default TemporaryClosedPageContainer
