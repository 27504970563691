import React, { useMemo } from 'react'
import { TablePaginationConfig } from 'antd'
import {
  useStoreViewContext,
} from './../../context/storeViewContext/StoreViewContext'
import { EnumConfigType } from '../../types/enums'
import { ITemporaryClosedItem } from '../../api/apiModel/temporaryClosed'
import { IPageInfo } from '../../types/interface'
import Table from '../tools/Table'
import moment from 'moment'
import { IOwnStoreItem } from '../../../src/api/apiModel/ownStore'

interface IITemporaryClosedTableProps {
  list: ITemporaryClosedItem[]
  total: number
  storeList: IOwnStoreItem[]
  pageInfo: IPageInfo
  onPageChange: (props: { size: number; page: number }) => void
  onClickByNo: (data: ITemporaryClosedItem) => void
}

const ITemporaryClosedTable = ({
  list,
  total,
  pageInfo,
  storeList,
  onPageChange,
  onClickByNo,
  ...props
}: IITemporaryClosedTableProps) => {
  const { configMap } = useStoreViewContext()

  const columns = [
    {
      title: 'CN',
      dataIndex: 'Store_Code',
      width: 120,
      fixed: true,
      render: (text, item) => (
        <a
          onClick={(e) => {
            onClickByNo(item)
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Store',
      dataIndex: 'Store_Name',
      fixed: true,
    },
    {
      title: 'Area',
      dataIndex: 'Store_Code',
      render: (id) => {
        return (
          <span>
            {
              configMap.get(EnumConfigType.Store_Region)?.
                find((item) => item.Id == storeList.find(
                  store => store.Store_Code === id)?.Region)?.Value
            }
          </span>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'Store_Code',
      render: (id) => {
        return (
          <span>
            {
              configMap.get(EnumConfigType.Store_Status)?.
                find((item) => item.Id == storeList.find(
                  store => store.Store_Code === id)?.Status)?.Value
            }
          </span>
        )
      },
    },
    {
      title: 'Closed Time',
      dataIndex: 'Closed_Date',
      render: (time) => (
        <span>{time ? moment(time * 1000).format('YYYY-MM-DD') : ''}</span>
      ),
    },
    {
      title: 'Reopen Time',
      dataIndex: 'Reopen_Date',
      render: (time) => (
        <span>{time ? moment(time * 1000).format('YYYY-MM-DD') : ''}</span>
      ),
    },
    {
      title: 'Remark',
      dataIndex: 'Remark',
    },
  ]

  const data = useMemo(() => list.map((item) => ({
    ...item,
    key: item.Id,
  })), [list])

  const onChangeByTable = (pagination: TablePaginationConfig) => {
    const { pageSize: size, current: page } = pagination
    if (pageInfo.size != size || pageInfo.page != page) {
      onPageChange({ size, page })
    }
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChangeByTable}
        scroll={{ x: 1200 }}
        pagination={{
          total,
          showTotal: (total) => `Total ${total} items`,
          current: pageInfo.page,
          pageSize: pageInfo.size,
          showSizeChanger: true,
        }}
      />
    </>
  )
}

export default ITemporaryClosedTable
