import React from 'react'
import { ITemporaryClosedItem } from '../../api/apiModel/temporaryClosed'
import TemporaryClosedTable from './Table'
import TemporaryClosedFilter from './Filter'
import { EnumOperateType } from '../../types/enums'
import { IOperateCallbackParams } from './TemporaryClosed'
import { Spin } from 'antd'
import { IPageInfo } from '../../types/interface'
import { IOwnStoreItem } from '../../../src/api/apiModel/ownStore'

interface ITemporaryClosedListProps {
  list: ITemporaryClosedItem[]
  isLoading: boolean
  storeList: IOwnStoreItem[]
  total: number
  pageInfo: IPageInfo
  filterData: object
  storeCodeList: { value: string; label?: string }[]
  operateCallback: (
    type: EnumOperateType,
    item?: IOperateCallbackParams
  ) => void
}

const TemporaryClosedListContent = ({
  list,
  total,
  pageInfo,
  isLoading,
  storeList,
  filterData,
  storeCodeList,
  operateCallback,
}: ITemporaryClosedListProps) => {
  const changeFilter = (data: object) => {
    const filter = {}
    for (const [key, value] of Object.entries(data)) {
      if (value) filter[key] = value
    }
    operateCallback(EnumOperateType.Filter, { filter })
  }

  return (
    <>
      <TemporaryClosedFilter
        filterData={filterData}
        storeCodeList={storeCodeList}
        changeFilter={changeFilter}
        addTemporaryClosed={() => {
          operateCallback(EnumOperateType.Add)
        }}
      />
      <Spin spinning={isLoading} delay={300}>
        <TemporaryClosedTable
          total={total}
          pageInfo={pageInfo}
          list={list}
          storeList={storeList}
          onPageChange={(pageInfo) =>
            operateCallback(EnumOperateType.ChangePage, { pageInfo })
          }
          onClickByNo={(item) => {
            operateCallback(EnumOperateType.Update, { item })
          }}
        />
      </Spin>
    </>
  )
}

export default TemporaryClosedListContent
